import React, { useState, useEffect } from 'react';
import { keyBy, without, uniq, filter } from 'lodash';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import './CustomerList.css';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

function CustomerList({ history, userId }) {
  // State
  const [loading, setLoading] = useState(false);
  const [keyedCustomers, setKeyedCustomers] = useState({});
  const [customers, setCustomers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filteredText, setFilteredText] = useState('');
  const [mappedUsersArray, setMappedUsersArray] = useState([]);

  const size = 2000;
  const [page, setPage] = useState(1);

  async function fetchMapppedUsers() {
    // console.log('user', userId);
    const url = `${process.env.REACT_APP_API_URL}/user/acumatica-customers/user/${userId}`;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const usersResponse = await axios
      .get(url, requestOptions)
      .then(o => o.data);

    setSelected(usersResponse);
    setMappedUsersArray(usersResponse);
  }

  async function fetchBranches() {
    // console.log('user', userId);
    const url = `${process.env.REACT_APP_QUOTES_API}/api/acumatica/branches`;
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const response = await axios.get(url, requestOptions).then(o => o.data);
    setBranches(response);
  }

  // Fetch Data by Page, initial Load
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const url = `${process.env.REACT_APP_QUOTES_API}/api/acumatica/customers?page=${page}&size=${size}`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      setKeyedCustomers(keyBy(res.data, 'CustomerID.value'));
      setCustomers(res.data);

      await fetchMapppedUsers();

      await fetchBranches();

      setLoading(false);
    }

    fetchData();
  }, [page]);

  const handleNext = async e => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append('page', page + 1);

    setPage(page + 1);

    history.push({
      search: params.toString(),
    });
  };

  const handlePrev = async e => {
    e.preventDefault();
    const params = new URLSearchParams();
    params.append('page', page - 1);

    setPage(page - 1);

    history.push({
      search: params.toString(),
    });
  };

  const handleChecked = async (e, customerId) => {
    const isChecked = e.target.checked;

    const url = `${process.env.REACT_APP_API_URL}/user/${userId}/acumatica-customers`;
    const payload = {
      items: [
        {
          customerId,
          action: isChecked ? 'ADD' : 'DELETE',
        },
      ],
    };
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    await axios.put(url, payload, requestOptions);

    await fetchMapppedUsers();
  };

  const handleBranchChecked = async (e, code) => {
    const filteredCustomers = customers
      .filter(cust => {
        return cust.ShippingBranch.value === code;
      })
      .map(cust => cust.CustomerID.value);

    setSelected(uniq([...selected, ...filteredCustomers]));
  };

  const handleSave = async e => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL}/user/${userId}/acumatica-customers`;
    const payload = {
      mode: 'REPLACE',
      items: selected.map(customerId => ({
        customerId,
      })),
    };
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    setLoading(true);
    await axios.put(url, payload, requestOptions);

    await new Promise(resolve => setTimeout(resolve, 1000));
    await fetchMapppedUsers();
    setLoading(false);
  };

  const handleClear = () => {
    setSelected([]);
  };

  const handleFilterInput = e => {
    e.preventDefault();
    setFilteredText(e.target.value);
  };

  // filter customer list on type
  useEffect(() => {
    setFilteredCustomers(
      filter(customers, customer => {
        return customer.CustomerName.value.toLowerCase().includes(filteredText);
      })
    );
  }, [filteredText]);

  const customerList = filteredText ? filteredCustomers : customers;

  console.log('keyed', keyedCustomers);
  return (
    <div>
      <Modal open={loading} onClose={() => {}}>
        <p />
      </Modal>

      <div className="list-header">
        <div className="list-save">
          <Button onClick={handleSave} variant="contained" color="secondary">
            Save
          </Button>
        </div>
      </div>
      {page > 1 && <button onClick={handlePrev}>Prev</button>}
      {customers.length === size && <button onClick={handleNext}>Next</button>}

      <div className="list-wrapper">
        <div className="left-list list">
          <h3>Select by Branch</h3>
          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>Branch</TableHeaderColumn>
                <TableHeaderColumn>Select</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {branches.map(data => {
                return (
                  <TableRow key={`branches:${data.id}`}>
                    <TableRowColumn>{data.name}</TableRowColumn>
                    <TableRowColumn>
                      <span
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => handleBranchChecked(data.code)}
                      >
                        Select
                      </span>
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="right-list list">
          <h3>Select by Customer</h3>

          <div>
            <label>Filter</label>
          </div>
          <input type="text" onChange={handleFilterInput} />
          <button
            onClick={e => {
              e.preventDefault();
              setFilteredText('');
            }}
          >
            Reset
          </button>
          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>Customer ID</TableHeaderColumn>
                <TableHeaderColumn>Action</TableHeaderColumn>
              </TableRow>
            </TableHeader>

            <TableBody displayRowCheckbox={false}>
              {(customerList || []).map(customer => {
                return (
                  <TableRow key={customer.id}>
                    <TableRowColumn>
                      {customer.CustomerName.value}
                    </TableRowColumn>
                    <TableRowColumn>
                      {!selected.includes(customer.CustomerID.value) && (
                        <span
                          style={{ color: 'green', cursor: 'pointer' }}
                          onClick={() => {
                            setSelected([
                              ...selected,
                              customer.CustomerID.value,
                            ]);
                          }}
                        >
                          Select
                        </span>
                      )}
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <div className="last-list list list-selected">
          <h3>Selected</h3>

          <Button
            onClick={handleClear}
            variant="contained"
            color="primary"
            size="small"
          >
            Clear
          </Button>

          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn>Select</TableHeaderColumn>
                <TableHeaderColumn>Customer ID</TableHeaderColumn>
              </TableRow>
            </TableHeader>

            <TableBody displayRowCheckbox={false}>
              {selected.map(customerId => {
                return (
                  <TableRow key={`selected:${customerId}`}>
                    <TableRowColumn>
                      <span
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => {
                          setSelected(without(selected, customerId));
                        }}
                      >
                        Remove
                      </span>
                    </TableRowColumn>
                    <TableRowColumn>
                      {keyedCustomers[customerId] &&
                        keyedCustomers[customerId].CustomerName.value}
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CustomerList);
